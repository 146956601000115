import React from "react"
import "../styles/filozofia.scss"

const Filozofia = (props) => {
  const opis = props.opis
  const title = props.title
  return (
    <div className="container filo-flex-container">
      <div className="filo-wrapper">
        <span className="filo-header">{title}</span>
        <span className="filo-text">{opis}</span>
      </div>
    </div>
  )
}
export default Filozofia
