import React from "react"
import "../styles/lekarze.scss"
import "bulma/css/bulma.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import Filozofia from "./filozofia"
import { Fade } from "react-reveal"

const Lekarze = ({ onas }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulPracownicy(filter: { node_locale: { eq: "pl" } }) {
        edges {
          node {
            imie
            rola
            jestlekarzem
            recepcja
            slug
            zdjecie {
              fixed {
                src
              }
            }
            childContentfulPracownicyOpisLekarzaRichTextNode {
              json
            }
          }
        }
      }
    }
  `)

  const lekarze = data.allContentfulPracownicy.edges
  const lekarzeBezRecepcji = lekarze.filter(lekarz => !lekarz?.node?.recepcja)
  const samaRecepcja = lekarze.filter(lekarz => lekarz?.node?.recepcja)
  return (
    <div className="container lekarze-list-container">
      <Fade>
        <Filozofia
          title="Nasz zespół"
          opis="Nasz wykwalifikowany personel dołoży wszelkich starań abyście czuli się Państwo komfortowo podczas wizyty w naszym gabinecie."
        />
      </Fade>
      {onas && (
        <Fade ssrFadeout>
          <div className="recepcja-subtitle">Lekarze i specjaliści:</div>
        </Fade>
      )}
      <section className="section-lekarze-wrapper columns">
        {lekarzeBezRecepcji.map((lekarz, id) => {
          const isSylwia = lekarz.node?.imie === "dr n. med. Sylwia Farfał - Kałucka"
          return (
            <Fade ssrFadeout delay={(id + 1) * 100}>
              <div
                key={`key-${lekarz.node?.imie}`}
                style={{
                  order: `${isSylwia ? 1 : lekarz.node?.jestlekarzem ? 2 : lekarz.node?.recepcja ? 4 : 3}`,
                }}
                className="lekarz-container"
              >
                <img alt="lekarz" className="is-rounded lekarz-zdjecie" src={lekarz.node?.zdjecie?.fixed?.src} />
                <div className="lekarz-opis-container">
                  <Link to={`/pracownicy/${lekarz.node?.slug}`}>
                    <div className="lekarz-node-imie">{lekarz.node?.imie}</div>
                  </Link>
                  <div className="lekarz-node-rola">{lekarz.node?.rola}</div>
                </div>
              </div>
            </Fade>
          )
        })}
      </section>
      <section style={{ flexDirection: "column" }} className="section-lekarze-wrapper">
        {onas && (
          <Fade ssrFadeout left>
            <div className="recepcja-subtitle">Recepcja i kontakt z pacjentem:</div>
          </Fade>
        )}
        <div style={{ display: "flex" }}>
          {onas &&
            samaRecepcja.map((lekarz, id) => {
              return (
                <Fade ssrFadeout delay={(id + 1) * 100}>
                  <div key={`key-${lekarz.node?.imie}`} className="lekarz-container">
                    <img alt="lekarz" className="is-rounded lekarz-zdjecie" src={lekarz.node?.zdjecie?.fixed?.src} />
                    <div className="lekarz-opis-container">
                      <div>
                        <div className="lekarz-node-imie">{lekarz.node?.imie}</div>
                      </div>
                      <div className="lekarz-node-rola">{lekarz.node?.rola}</div>
                    </div>
                  </div>
                </Fade>
              )
            })}
        </div>
      </section>
    </div>
  )
}
export default Lekarze
